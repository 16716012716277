import React, { ReactElement } from 'react'

import { ClassNames, SerializedStyles } from '@emotion/core'
import { format } from 'date-fns'
import ReactCalendar, {
  CalendarProps as ReactCalendarProps,
} from 'react-calendar/dist/entry.nostyle'

import { calendarStyles } from '@mindfulchefuk/design-system/Calendar/Calendar.styles'
import { Icon } from '@mindfulchefuk/design-system/Icon/Icon'
import { convertSerializedStylesToString } from '@mindfulchefuk/design-system/system/utils/convertSerializedStylesToString'

interface DayOfTheWeek {
  [day: string]: string
}
const DAY_OF_THE_WEEK_MAP: DayOfTheWeek = {
  Sun: 'Sun',
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',
}

export const Calendar = ({
  prevLabel = <Icon type="arrow-left" />,
  nextLabel = <Icon type="arrow-right" />,
  minDetail = 'month',
  nextAriaLabel = 'Next month',
  prevAriaLabel = 'Previous month',
  serializedStyles = calendarStyles,
  next2Label = null,
  prev2Label = null,
  formatShortWeekday = (_, date) => DAY_OF_THE_WEEK_MAP[format(date, 'ddd')],
  ...props
}: ReactCalendarProps & {
  serializedStyles: SerializedStyles | SerializedStyles[]
}): ReactElement => {
  const minDate = new Date(props.minDate)
  const maxDate = new Date(props.maxDate)

  return (
    <ClassNames>
      {({ css, cx }) => (
        <ReactCalendar
          className={convertSerializedStylesToString(css, cx, serializedStyles)}
          minDetail={minDetail}
          formatShortWeekday={formatShortWeekday}
          prevLabel={prevLabel}
          nextLabel={nextLabel}
          nextAriaLabel={nextAriaLabel}
          prevAriaLabel={prevAriaLabel}
          next2Label={next2Label}
          prev2Label={prev2Label}
          {...props}
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
    </ClassNames>
  )
}
